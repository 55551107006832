import React, { memo } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import PlusGemsShopBanner from './components/PlusGemsShopBanner/PlusGemsShopBanner';
import { PlusGemsShopContainer } from './components/PlusGemsShopContainer/PlusGemsShopContainer';
import styles from './PlusShopTab.css';
import { HeaderSideMenuTabs } from '../../../constants/HeaderSideMenuTabs';
import UserService from '../../../services/UserService';
import { activePageSelector } from '../../../store/ducks/layoutSelectors';

export const PlusShopTab = memo(() => {
  const isLoggedIn = UserService.isUserLoggedIn();
  const isSubscriber = UserService.isUserSubscriber();
  const currentTab = useSelector(activePageSelector);
  const isGemsTabDarkBackground = isLoggedIn && currentTab === HeaderSideMenuTabs.SHOP_TAB;
  const isAdvantageTabDarkBackground = isSubscriber && currentTab === HeaderSideMenuTabs.SUBSCRIPTION_TAB;

  return (
    <div
      className={classNames(styles.tabContainer, {
        [styles.gemsTabDarkBackground]: isGemsTabDarkBackground,
        [styles.advantageTabDarkBackground]: isAdvantageTabDarkBackground
      })}
      role="region"
      aria-label="shop"
    >
      <PlusGemsShopBanner />
      <PlusGemsShopContainer />
    </div>
  );
});
PlusShopTab.displayName = 'PlusShopTab';
