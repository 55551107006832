import React, { FC, useEffect } from 'react';

import { push } from 'connected-react-router';
import { Trans, useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';


import { ArkadiumPlusLogo } from './ArkadiumPlusLogo';
import styles from './PlusSelectSubscription.css';
import { AppLoader } from '../../../../../atoms/AppLoader/AppLoader';
import { I18nText } from '../../../../../atoms/i18nText/i18nText';
import { NavLink } from '../../../../../atoms/Link/Link';
import { PaymentType, RecurlyPlans } from '../../../../../constants/RecurlyPurchase';
import { getPlanByCode, SubscriptionPlans } from '../../../../../constants/SubscriptionPlan';
import { Analytics } from '../../../../../services/Analytics/Analytics';
import { UrlService } from '../../../../../services/UrlService';
import { setActiveSubscriptionPlan } from '../../../../../store/ducks/subscription/common';
import { getSubscriptionPlans } from '../../../../../templates/Profile/Tabs/PaymentAndSubscription/JoinBlock/JoinBlock';
import { PlusAnnualCard } from '../PlusAnnualCard/PlusAnnualCard';
import { PlusBenefitsList } from '../PlusBenefitsList/PlusBenefitsList';
import { PlusMonthlyCard } from '../PlusMonthlyCard/PlusMonthlyCard';

export const PlusSelectSubscription: FC = React.memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const plans = useSelector(({ subscriptionPlans }) => subscriptionPlans);
  const subscriptionSource = useSelector(({ subscriptionSource }) => subscriptionSource);
  const annualPlanName = RecurlyPlans[PaymentType.subscription][SubscriptionPlans.ANNUAL].plan;
  const monthlyPlanName = RecurlyPlans[PaymentType.subscription][SubscriptionPlans.MONTHLY].plan;

  PlusSelectSubscription.displayName = 'SelectSubscription';

  const handleSubscribe = (plan: string) => {
    void Analytics.trackEvent(
      Analytics.subscription.entryPointClick(
        subscriptionSource,
        plan === SubscriptionPlans.MONTHLY ? 'monthly' : 'annual'
      )
    );
    batch(() => {
      dispatch(setActiveSubscriptionPlan(plan));
      dispatch(push('/subscription'));
    });
  };

  useEffect(() => {
    if (!plans) {
      getSubscriptionPlans(dispatch, null);
    }
  }, [plans]);

  return (
    <>
      <div className={styles.topBlock}>
        <div className={styles.mainInfo}>
          <div className={styles.leftSide}>
            <ArkadiumPlusLogo />

          </div>
          <div className={styles.rightSide}>
            <PlusBenefitsList t={t} />
          </div>
        </div>
      </div>
      <div className={styles.advantagesContainer}>
        <div className={styles.advantagesRow}>
          {plans?.length ? (
            <>
              <PlusAnnualCard
                plan={getPlanByCode(annualPlanName, plans)}
                monthlyPlan={getPlanByCode(monthlyPlanName, plans)}
                btnLabel={t('SELECT_SUBSCRIPTION.PLUS_SUBSCRIBE_BTN_LABEL')}
                handlerBtn={() => handleSubscribe(SubscriptionPlans.ANNUAL)}
              />
              <PlusMonthlyCard
                plan={getPlanByCode(monthlyPlanName, plans)}
                btnLabel={t('SELECT_SUBSCRIPTION.SUBSCRIBE_BTN_LABEL')}
                handlerBtn={() => handleSubscribe(SubscriptionPlans.MONTHLY)}
              />
            </>
          ) : (
            <AppLoader />
          )}
        </div>

        <div className={styles.aboutRow}>
          <div className={styles.aboutSubscription}>
            <Trans i18nKey="SELECT_SUBSCRIPTION.PLUS_SUBSCRIPTIONS_TERMS" />
          </div>
          <div className={styles.subscriptionLinks}>
            <NavLink to="/arkadium-player-agreement.pdf" target="_blank" rel="noopener">
              <I18nText keyName="PLAYER_AGREEMENT" />
            </NavLink>{','}&nbsp;
            <NavLink to="/arkadium-advantage-agreement.pdf" target="_blank" rel="noopener">
              <I18nText keyName="SUBSCRIPTION_AGREEMENT" />
            </NavLink>&nbsp;
            {t('SELECT_SUBSCRIPTION.AND')}&nbsp;
            <NavLink to={UrlService.createURL('privacy-policy')} target="_blank" rel="noopener">
              <I18nText keyName="FOOTER_PRIVACY_POLICY" />
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
});
