import React, { FC, useEffect, useState } from 'react';

import classNames from 'classnames';

import styles from './PlusMonthlyCard.css';
import { Button } from '../../../../../FigmaStyleguide/Button/Button';
import { SubscriptionPlan } from '../../../../../models/Subscription/SubscriptionData';
import { ManageType } from '../../../SubscriptionTab/ManagePlan/ManagePlan';


type MonthlyCardProps = {
  btnLabel: string;
  manageType?: ManageType;
  managePlanStyles?: boolean;
  plan: SubscriptionPlan;
  handlerBtn: () => void;
  unsetHeight?: boolean;
};
export const PlusMonthlyCard: FC<MonthlyCardProps> = React.memo(
  ({ btnLabel, managePlanStyles, manageType, plan, handlerBtn, unsetHeight = false }: MonthlyCardProps) => {
    PlusMonthlyCard.displayName = 'MonthlyCard';

    const [planPriceLabel, setPlanPriceLabel] = useState<number>();
    const [currencySign, setCurrencySign] = useState<string>();

    useEffect(() => {
      const planFullPrice = plan?.currencies[0]?.unitAmount;
      const planPriceValue = planFullPrice / plan?.intervalLength;
      const planLabel = Math.floor(planPriceValue * 100) / 100;
      const planCurrency = plan?.currencies[0]?.currency === 'USD' ? '$' : ''; // create a helper for getting currencies signs

      setPlanPriceLabel(planLabel);
      setCurrencySign(planCurrency);
    }, [plan]);

    return (
      <div
        className={classNames(
          styles.container,
          managePlanStyles && styles.managePlanStyles,
          manageType === ManageType.UPDATE && styles.managePlanUpdate,
          { [styles.unsetHeight]: unsetHeight }
        )}
      >
        <div className={styles.info}>
          <div className={styles.valuesRow}>
            <div className={styles.heading}>Monthly</div>
            <div>
              <span className={styles.price}>
                {currencySign}
                {planPriceLabel}
              </span>&nbsp;
              <span className={styles.monthLabel}>/month</span>
            </div>
            <div className={styles.savings}>
              $83.88 /year
            </div>
          </div>
        </div>
        {manageType !== ManageType.UPDATE && (
          <div className={styles.btnRow}>
            <Button outlined className={styles.btnSubscribe} onClick={handlerBtn}>
              {btnLabel}
            </Button>
          </div>
        )}
      </div>
    );
  }
);
